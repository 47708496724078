<template>
    <el-dialog
            title="资源申请"
            :close-on-click-modal="false"
            v-dialogDrag
            width="80%"
            :visible.sync="visible">
        <el-descriptions title="申请借阅的资源"></el-descriptions>
        <el-table
                :data="dataList"
                v-loading="loading"
                size="small"
                max-height="250"
                class="table m_b2">
            <el-table-column show-overflow-tooltip prop="general_num" label="藏品编号">
              <template slot-scope="scope">
                {{ scope.row.general_num ? scope.row.general_num : scope.row.help_num }}
              </template>
            </el-table-column>
            <el-table-column
                    show-overflow-tooltip
                    prop="collection_name"
                    label="藏品名称">
            </el-table-column>
            <el-table-column
                    show-overflow-tooltip
                    prop="resource_name"
                    label="资源名称">
            </el-table-column>
            <el-table-column label="类别">
                <template slot-scope="scope">
                    <span v-if="scope.row.column_name!=null">{{scope.row.column_name}}</span>
                    <span v-else>{{scope.row.type_name}}</span>
                </template>
            </el-table-column>
            <el-table-column
                    prop="resource_keys"
                    label="标签" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div v-if="scope.row.resource_keys!=null&& scope.row.resource_keys.length > 0">
                            <span style="margin-right: 5px"
                                  v-for="(item,index) in scope.row.resource_keys">{{item}};</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column width="150"
                             fixed="right"
                             label="操作">
                <template slot-scope="scope">
                    <el-button size="mini" type="text" @click="view(scope.row)">详情</el-button>
                    <el-button size="mini" type="text" @click="del(scope.$index)">移除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-descriptions title="填写借阅单"></el-descriptions>
        <el-form ref="inputForm" size="small" :model="inputForm" label-width="150px" :rules="dataRule">
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="借阅人：">
                        <el-input v-model="inputForm.name" disabled></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="借阅开始日期：" prop="endTime">
                        <el-date-picker style="width: 100%"
                                        v-model="inputForm.endTime"
                                        type="date"
                                        value-format="yyyy-MM-dd"
                                        placeholder="选择日期时间" :picker-options="pickerOptions">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="借阅天数：" prop="loanDays">
                        <el-input v-model.tirm="inputForm.loanDays" maxlength="3" class="w90 m_r1" placeholder="请输入天数" @input="inputForm.loanDays = inputForm.loanDays.replace(/[^\d]/g, '')"></el-input>天
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label="借阅目的：" prop="remarks">
                        <el-input
                                type="textarea"
                                :rows="3"
                                placeholder="请输入内容"
                                v-model.trim="inputForm.remarks" maxlength="100">
                        </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div slot="footer" class="text_center">
            <el-button size="small" @click="visible = false">取消</el-button>
            <el-button size="small" @click="save()" v-noMoreClick type="primary">提交</el-button>
        </div>
        <el-dialog class="retrievedDetailsBox"
                :visible.sync="innerVisible" v-if="innerVisible"
                :append-to-body="true" :fullscreen="true" :show-close="false">
            <RetrievedDetails ref="retrievedDetails" viewData="BorrowForm" @getData="getShowData"></RetrievedDetails>
        </el-dialog>
    </el-dialog>
</template>

<script>
    import RetrievedDetails from './retrievedDetails' //详情

    export default {
        components: {RetrievedDetails},
        data() {
            var checkNum = (rule, value, callback) => {
                if (value && !/^\+?[1-9]{1}[0-9]{0,2}\d{0,0}$/.test(value)) {
                    callback(new Error('请输入正确的借阅天数'));
                } else if (value && value > 365) {
                    callback(new Error('最大借阅天数不得超过365天'));
                } else {
                    callback()
                }
            };
            return {
                pickerOptions: {
                    disabledDate(v) {
                        return v.getTime() < new Date().getTime() - 86400000;//  - 86400000是否包括当天
                    }
                },
                dataList: [],
                visible: false,
                loading: false,
                inputForm: {
                    name: JSON.parse(sessionStorage.getItem('userInfo')).name,
                    endTime: "",
                    loanDays: "",
                    remarks: ""
                },
                dataRule: {
                    endTime: [{required: true, message: '借阅开始日期不能为空', trigger: 'change'}],
                    loanDays: [
                        {required: true, message: '借阅天数不能为空', trigger: 'blur'},
                        {validator: checkNum, trigger: 'blur'}
                    ],
                    remarks: [{required: true, message: '借阅目的不能为空', trigger: 'blur'}],
                },
                // 查看
                innerVisible: false,
            }
        },
        methods: {
            init(dataList) {
                this.dataList = JSON.parse(JSON.stringify(dataList))
                this.visible = true
                this.loading = false
                this.$nextTick(() => {
                    this.$refs['inputForm'].resetFields()
                })
            },
            getShowData() {
                this.innerVisible = false
            },
            // 查看
            view(row) {
                var newRow = JSON.parse(JSON.stringify(row))
                newRow.id = newRow.foreign_key_id
                this.innerVisible = true
                this.$nextTick(()=> {
                    this.$refs.retrievedDetails.init('BorrowForm', newRow)
                })

            },
            // 删除
            del(index) {
                if (this.dataList.length > 1) {
                    this.dataList.splice(index, 1);    //删除数组中的该条数据
                } else {
                    this.$message.warning("当前只有一个借阅文件，不允许删除！")
                }
            },
            save() {
                this.$refs['inputForm'].validate((valid) => {
                    if (valid) {
                        let root = []
                        this.dataList.map(item => {
                            root.push({
                                id: item.id,
                                foreignKeyId: item.foreign_key_id,
                                foreignType: item.divType!=null?item.divType:item.resource_type,
                                endTime: this.inputForm.endTime,
                                loanDays: this.inputForm.loanDays,
                                remarks: this.inputForm.remarks,
                            })
                        })
                        this.$axios(this.api.dyyg.homeBorrowingSave, root, 'post').then(data => {
                            if (data.status) {
                                this.visible = false
                                this.$emit('refreshDataList')
                                this.$emit('search')
                                this.$message.success('提交成功')
                            } else {
                                this.$message.error("提交失败")
                            }
                        })
                    }
                })
            }

        }
    }
</script>

<style scoped>
    .resourcesIcon {
        width: 2rem;
        margin: 0 0.5rem;
    }
    .retrievedDetailsBox >>> .el-dialog__body, .retrievedDetailsBox >>> .el-dialog__header {
        padding: 0 !important;
    }
    .retrievedDetailsBox >>> .el-dialog.is-fullscreen::-webkit-scrollbar {
        display: none;
    }
</style>
